



































































































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DataFormat from '@/shared/utils/dataFormat';
import { reservationModule, ticketModule } from '@/store';

@Component({
  components: {
    'reserve-cancel': () => import('@/components/popup/ReserveCancel.vue')
  }
})
export default class PaymentResult extends mixins(DataFormat) {
  @Prop()
  public type!: string;
  @Prop()
  public selectLang!: any;

  get reservation(): any {
    return reservationModule.getDetailReservation;
  }

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      const id = encodeURIComponent(window.atob(to.query.reservation_id));
      localStorage.removeItem('cartData');
      reservationModule.fetchDetailReservation(to.query.reservation_id).then((res: any) => {
        console.log(res.data);
        vm.eventGoalProduct(res.data);
        if (res.data.product && res.data.product.id) {
          ticketModule
            .fetchProduct(res.data.product.id);
        }
      });
    });
  }

  public refresh(id?: any) {
    reservationModule.fetchDetailReservation(id).then((res: any) => {
      if (res.data.product && res.data.product.id) {
        ticketModule
          .fetchProduct(res.data.product.id);
      }
    });
  }

  public eventGoalProduct(data) {
    const eventName = 'goal_product';
    const eventParams: any = {
      id: data.product.id.toString(),
      name: data.product.title,
      order_id: data.order.id.toString(),
      pay_type: data.order.payment_info.pay_type,
      total_price: data.order.payment_info.total_price,
      cash_price: data.order.payment_info.cash_price,
      option_id: null,
      option_name: null,
      option_price: null,
      option_count: null,
      reservation_id: data.id.toString()
    }
    for (const option of data.buy_option) {
      eventParams.option_id = option.product_option_id.toString()
      eventParams.option_name = option.title
      eventParams.option_price = option.discount_price
      eventParams.option_count = option.count
    }
  }

  public copyNoti() {
    this.$q.notify({
      message: '예약 정보가 복사 되었습니다. <br />지금 바로 공유 해보세요.',
      html: true
    });
  }
  public addInfoText(item: any) {
    if (
      item.key === 'customer_allergy_detail' ||
      item.title === '알러지 세부사항'
    ) {
      return item.value;
    }
    if (item.value && item.value[0]) {
      if (item.value[0].title_detail === '') {
        const text: any = [];
        item.value.map((row: any) => {
          text.push(row.title);
        });
        return text.join(', ');
      } else {
        const text: any = [];
        item.value.map((row: any) => {
          text.push(
            row.title + ' : ' + row.title_detail
          );
        });
        return text.join(`\n`);
      }
    }
  }
  public reservCancelModal() {
    document.querySelector('html' as any).classList.add('open-modal');
    this.$modal.show('reserveCancel');
  }
  // 인원 성인만 있는지 체크
  get onlyAdultCheck() {
    const re = this.reservation.party_size_detail.filter((row: any) => {
      return row.title !== '성인' && Number(row.party_size) !== 0;
    });
    if (re.length) {
      return true;
    }
    return false;
  }
  // 마지막 인원 타입
  get lastPersonKindIndex() {
    let type = '';
    this.reservation.party_size_detail.filter((row: any) => {
      if (row.party_size !== 0) {
        type = row.title;
      }
    });
    if (type !== '') {
      return this.reservation.party_size_detail.findIndex((row: any) => {
        return row.title === type;
      })
    }
    return 0;
  }
  get shareText() {
    if (this.reservation.share_info) {
      const target = this.reservation.share_info.body.indexOf('http');
      if (target !== -1) {
        return this.reservation.share_info.body.substring(0, target);
      }
    }
    return this.reservation.share_info && this.reservation.share_info.body;
  }
  get statusMainText() {
    if (this.reservation.status === 'change_confirmed') {
      return '변경이 완료되었습니다.';
    } else if (this.reservation.status === 'cancelled') {
      return '예약이 취소되었습니다.';
    } else if (this.reservation.status === 'appeared') {
      return '방문 처리되었습니다.';
    } else if (this.reservation.status === 'noshow') {
      return '노쇼 처리되었습니다.';
    } else if (this.reservation.status === 'confirmed') {
      return '결제가 완료되었습니다.';
    }
  }
  get product(): any {
    return ticketModule.productInfo;
  }
}
